// generated with @7nohe/openapi-react-query-codegen@1.4.1 

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AccountsService, ConnectionsService, ListingsService, OwnersService, PaymentsService, RecurringFeesService, ReservationsService, StatementsService, TaxRatesService, TeamsService, TransactionsService } from "../requests/services.gen";
import * as Common from "./common";
export const usePaymentsServiceGetPayment = <TData = Common.PaymentsServiceGetPaymentDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePaymentsServiceGetPaymentKeyFn({ id, xTenantId }, queryKey), queryFn: () => PaymentsService.getPayment({ id, xTenantId }) as TData, ...options });
export const usePaymentsServiceGetPayments = <TData = Common.PaymentsServiceGetPaymentsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, limit, page, status, xTenantId }: {
  date?: string;
  limit?: number;
  page?: number;
  status?: "arrived" | "cancelled" | "pending";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePaymentsServiceGetPaymentsKeyFn({ date, limit, page, status, xTenantId }, queryKey), queryFn: () => PaymentsService.getPayments({ date, limit, page, status, xTenantId }) as TData, ...options });
export const usePaymentsServiceGetPaymentsByCurrency = <TData = Common.PaymentsServiceGetPaymentsByCurrencyDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, xTenantId }: {
  date?: string;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePaymentsServiceGetPaymentsByCurrencyKeyFn({ date, xTenantId }, queryKey), queryFn: () => PaymentsService.getPaymentsByCurrency({ date, xTenantId }) as TData, ...options });
export const useReservationsServiceGetReservation = <TData = Common.ReservationsServiceGetReservationDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReservationsServiceGetReservationKeyFn({ id, xTenantId }, queryKey), queryFn: () => ReservationsService.getReservation({ id, xTenantId }) as TData, ...options });
export const useReservationsServiceGetReservations = <TData = Common.ReservationsServiceGetReservationsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, dateField, id, limit, listingId, page, status, xTenantId }: {
  date?: string;
  dateField?: "bookedAt" | "checkIn" | "checkOut" | "payment" | "intersection";
  id?: string;
  limit?: number;
  listingId?: string;
  page?: number;
  status?: "cancelled" | "booked" | "inquiry";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReservationsServiceGetReservationsKeyFn({ date, dateField, id, limit, listingId, page, status, xTenantId }, queryKey), queryFn: () => ReservationsService.getReservations({ date, dateField, id, limit, listingId, page, status, xTenantId }) as TData, ...options });
export const useListingsServiceGetListingOwnershipPeriods = <TData = Common.ListingsServiceGetListingOwnershipPeriodsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ listingId, xTenantId }: {
  listingId?: string;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingOwnershipPeriodsKeyFn({ listingId, xTenantId }, queryKey), queryFn: () => ListingsService.getListingOwnershipPeriods({ listingId, xTenantId }) as TData, ...options });
export const useListingsServiceGetListingOwnershipPeriod = <TData = Common.ListingsServiceGetListingOwnershipPeriodDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingOwnershipPeriodKeyFn({ id, xTenantId }, queryKey), queryFn: () => ListingsService.getListingOwnershipPeriod({ id, xTenantId }) as TData, ...options });
export const useListingsServiceGetListing = <TData = Common.ListingsServiceGetListingDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingKeyFn({ id, xTenantId }, queryKey), queryFn: () => ListingsService.getListing({ id, xTenantId }) as TData, ...options });
export const useListingsServiceGetListings = <TData = Common.ListingsServiceGetListingsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, status, xTenantId }: {
  limit?: number;
  page?: number;
  status?: "active" | "inactive";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingsKeyFn({ limit, page, status, xTenantId }, queryKey), queryFn: () => ListingsService.getListings({ limit, page, status, xTenantId }) as TData, ...options });
export const useOwnersServiceGetOwner = <TData = Common.OwnersServiceGetOwnerDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOwnersServiceGetOwnerKeyFn({ id, xTenantId }, queryKey), queryFn: () => OwnersService.getOwner({ id, xTenantId }) as TData, ...options });
export const useOwnersServiceGetOwners = <TData = Common.OwnersServiceGetOwnersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, status, xTenantId }: {
  limit?: number;
  page?: number;
  status?: "active" | "inactive";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOwnersServiceGetOwnersKeyFn({ limit, page, status, xTenantId }, queryKey), queryFn: () => OwnersService.getOwners({ limit, page, status, xTenantId }) as TData, ...options });
export const useTeamsServiceGetTeam = <TData = Common.TeamsServiceGetTeamDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTeamsServiceGetTeamKeyFn({ id }, queryKey), queryFn: () => TeamsService.getTeam({ id }) as TData, ...options });
export const useTeamsServiceGetTeams = <TData = Common.TeamsServiceGetTeamsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, limit, page, status, xTenantId }: {
  date?: string;
  limit?: number;
  page?: number;
  status?: "active" | "inactive" | "deleted" | "onboarding";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTeamsServiceGetTeamsKeyFn({ date, limit, page, status, xTenantId }, queryKey), queryFn: () => TeamsService.getTeams({ date, limit, page, status, xTenantId }) as TData, ...options });
export const useRecurringFeesServiceGetRecurringFeeListingSubscriptions = <TData = Common.RecurringFeesServiceGetRecurringFeeListingSubscriptionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, recurringFeeId, xTenantId }: {
  limit?: number;
  page?: number;
  recurringFeeId?: string;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesServiceGetRecurringFeeListingSubscriptionsKeyFn({ limit, page, recurringFeeId, xTenantId }, queryKey), queryFn: () => RecurringFeesService.getRecurringFeeListingSubscriptions({ limit, page, recurringFeeId, xTenantId }) as TData, ...options });
export const useRecurringFeesServiceGetRecurringFee = <TData = Common.RecurringFeesServiceGetRecurringFeeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesServiceGetRecurringFeeKeyFn({ id, xTenantId }, queryKey), queryFn: () => RecurringFeesService.getRecurringFee({ id, xTenantId }) as TData, ...options });
export const useRecurringFeesServiceGetRecurringFees = <TData = Common.RecurringFeesServiceGetRecurringFeesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, xTenantId }: {
  limit?: number;
  page?: number;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesServiceGetRecurringFeesKeyFn({ limit, page, xTenantId }, queryKey), queryFn: () => RecurringFeesService.getRecurringFees({ limit, page, xTenantId }) as TData, ...options });
export const useStatementsServiceGetStatementLayouts = <TData = Common.StatementsServiceGetStatementLayoutsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, xTenantId }: {
  limit?: number;
  page?: number;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetStatementLayoutsKeyFn({ limit, page, xTenantId }, queryKey), queryFn: () => StatementsService.getStatementLayouts({ limit, page, xTenantId }) as TData, ...options });
export const useStatementsServiceGetStatmentLayout = <TData = Common.StatementsServiceGetStatmentLayoutDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetStatmentLayoutKeyFn({ id, xTenantId }, queryKey), queryFn: () => StatementsService.getStatmentLayout({ id, xTenantId }) as TData, ...options });
export const useStatementsServiceGetOwnerStatement = <TData = Common.StatementsServiceGetOwnerStatementDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, id, summaryType, viewAs, xTenantId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  id: string;
  summaryType?: "invoice" | "default";
  viewAs?: unknown;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetOwnerStatementKeyFn({ collectionMode, id, summaryType, viewAs, xTenantId }, queryKey), queryFn: () => StatementsService.getOwnerStatement({ collectionMode, id, summaryType, viewAs, xTenantId }) as TData, ...options });
export const useStatementsServiceGetOwnerStatements = <TData = Common.StatementsServiceGetOwnerStatementsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, summaryType, templateId, viewAs, xTenantId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  limit?: number;
  listingIds?: string;
  month?: string;
  ownerIds?: string;
  ownershipPeriodIds?: string;
  page?: number;
  status?: "all" | "draft" | "inReview" | "void" | "virtual" | "published";
  summaryType?: "invoice" | "default";
  templateId?: string;
  viewAs?: unknown;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetOwnerStatementsKeyFn({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, summaryType, templateId, viewAs, xTenantId }, queryKey), queryFn: () => StatementsService.getOwnerStatements({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, summaryType, templateId, viewAs, xTenantId }) as TData, ...options });
export const useStatementsServiceGetOwnerStatementPdf = <TData = Common.StatementsServiceGetOwnerStatementPdfDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, payoutReceivedByOwner, summaryType, xTenantId }: {
  id: string;
  payoutReceivedByOwner?: boolean;
  summaryType?: "invoice" | "default";
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetOwnerStatementPdfKeyFn({ id, payoutReceivedByOwner, summaryType, xTenantId }, queryKey), queryFn: () => StatementsService.getOwnerStatementPdf({ id, payoutReceivedByOwner, summaryType, xTenantId }) as TData, ...options });
export const useTransactionsServiceGetTransactions = <TData = Common.TransactionsServiceGetTransactionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, limit, page, type, xTenantId }: {
  date?: string;
  limit?: number;
  page?: number;
  type?: "ownerExpense" | "expense" | "deposit";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTransactionsServiceGetTransactionsKeyFn({ date, limit, page, type, xTenantId }, queryKey), queryFn: () => TransactionsService.getTransactions({ date, limit, page, type, xTenantId }) as TData, ...options });
export const useTransactionsServiceGetTransaction = <TData = Common.TransactionsServiceGetTransactionDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTransactionsServiceGetTransactionKeyFn({ id, xTenantId }, queryKey), queryFn: () => TransactionsService.getTransaction({ id, xTenantId }) as TData, ...options });
export const useConnectionsServiceGetConnection = <TData = Common.ConnectionsServiceGetConnectionDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTenantId }: {
  id: string;
  xTenantId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseConnectionsServiceGetConnectionKeyFn({ id, xTenantId }, queryKey), queryFn: () => ConnectionsService.getConnection({ id, xTenantId }) as TData, ...options });
export const useConnectionsServiceGetConnections = <TData = Common.ConnectionsServiceGetConnectionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ appId, limit, page, status, tenantId }: {
  appId?: string;
  limit?: number;
  page?: number;
  status?: string;
  tenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseConnectionsServiceGetConnectionsKeyFn({ appId, limit, page, status, tenantId }, queryKey), queryFn: () => ConnectionsService.getConnections({ appId, limit, page, status, tenantId }) as TData, ...options });
export const useAccountsServiceGetAccounts = <TData = Common.AccountsServiceGetAccountsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, type, xTenantId }: {
  limit?: number;
  page?: number;
  type?: "ledger" | "bank" | "recurringFee";
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountsServiceGetAccountsKeyFn({ limit, page, type, xTenantId }, queryKey), queryFn: () => AccountsService.getAccounts({ limit, page, type, xTenantId }) as TData, ...options });
export const useTaxRatesServiceGetTaxRates = <TData = Common.TaxRatesServiceGetTaxRatesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ countryCode, limit, page, xTenantId }: {
  countryCode?: string;
  limit?: number;
  page?: number;
  xTenantId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTaxRatesServiceGetTaxRatesKeyFn({ countryCode, limit, page, xTenantId }, queryKey), queryFn: () => TaxRatesService.getTaxRates({ countryCode, limit, page, xTenantId }) as TData, ...options });
export const usePaymentsServiceCreatePayment = <TData = Common.PaymentsServiceCreatePaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { uniqueRef?: string; paidAt?: string; connectionId: string; currency: string; description: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; lines: { type: string; description?: string; reservationId?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { uniqueRef?: string; paidAt?: string; connectionId: string; currency: string; description: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; lines: { type: string; description?: string; reservationId?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => PaymentsService.createPayment({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceCreateReservationJournalEntries = <TData = Common.ReservationsServiceCreateReservationJournalEntriesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; status?: "cancelled" | "booked" | "inquiry"; date?: string; dateField?: "bookedAt" | "checkIn" | "checkOut" | "payment" | "intersection"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; status?: "cancelled" | "booked" | "inquiry"; date?: string; dateField?: "bookedAt" | "checkIn" | "checkOut" | "payment" | "intersection"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ReservationsService.createReservationJournalEntries({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceCreateReservation = <TData = Common.ReservationsServiceCreateReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { currency?: string; uniqueRef?: string; connectionId: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials: { type: string; description?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { currency?: string; uniqueRef?: string; connectionId: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials: { type: string; description?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ReservationsService.createReservation({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceCreateListingOwnershipPeriod = <TData = Common.ListingsServiceCreateListingOwnershipPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { listingId: string; startAt?: string; endAt?: string; owners: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { listingId: string; startAt?: string; endAt?: string; owners: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ListingsService.createListingOwnershipPeriod({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceCreateListing = <TData = Common.ListingsServiceCreateListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { uniqueRef?: string; connectionId?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnershipPeriod?: { startAt?: string; endAt?: string; owners: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; }; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { uniqueRef?: string; connectionId?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnershipPeriod?: { startAt?: string; endAt?: string; owners: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; }; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ListingsService.createListing({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useOwnersServiceCreateOwner = <TData = Common.OwnersServiceCreateOwnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { address: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; type: "individual" | "company"; email?: string; phone?: string; connectionId?: string; firstName: string; name: string; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { address: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; type: "individual" | "company"; email?: string; phone?: string; connectionId?: string; firstName: string; name: string; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => OwnersService.createOwner({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceCreateTeam = <TData = Common.TeamsServiceCreateTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { defaultCurrency?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { defaultCurrency?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => TeamsService.createTeam({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceGenerateTeamData = <TData = Common.TeamsServiceGenerateTeamDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => TeamsService.generateTeamData({ id, xTenantId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceCreateRecurringFeeListingSubscription = <TData = Common.RecurringFeesServiceCreateRecurringFeeListingSubscriptionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => RecurringFeesService.createRecurringFeeListingSubscription({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceCreateRecurringFee = <TData = Common.RecurringFeesServiceCreateRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { uniqueRef?: string; name: string; formula: string; icon?: string; type: "managementFee" | "additionalFee"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { uniqueRef?: string; name: string; formula: string; icon?: string; type: "managementFee" | "additionalFee"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => RecurringFeesService.createRecurringFee({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceCreateStatementLayout = <TData = Common.StatementsServiceCreateStatementLayoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => StatementsService.createStatementLayout({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceCreateOwnerStatement = <TData = Common.StatementsServiceCreateOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; collectionMode?: "auto" | "all" | "currentMonthOnly"; listingId: string; listingPeriodIds?: string[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; collectionMode?: "auto" | "all" | "currentMonthOnly"; listingId: string; listingPeriodIds?: string[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => StatementsService.createOwnerStatement({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceCreateTransactionJournalEntries = <TData = Common.TransactionsServiceCreateTransactionJournalEntriesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; date?: string; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; date?: string; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => TransactionsService.createTransactionJournalEntries({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceCreateTransaction = <TData = Common.TransactionsServiceCreateTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { description: string; accountId?: string; accountRef?: string; type: "ownerExpense" | "expense" | "deposit"; date: string; currency: string; lines: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; accountAssignment?: "deposit" | "adjustment_complaint" | "deferredRevenue" | "deposit_merchantFee" | "expense_markup" | "expense_salesTax" | "ownerStatement_credit"; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; contactId?: string; markup?: { accountId?: string; accountRef?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { description: string; accountId?: string; accountRef?: string; type: "ownerExpense" | "expense" | "deposit"; date: string; currency: string; lines: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; accountAssignment?: "deposit" | "adjustment_complaint" | "deferredRevenue" | "deposit_merchantFee" | "expense_markup" | "expense_salesTax" | "ownerStatement_credit"; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; contactId?: string; markup?: { accountId?: string; accountRef?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => TransactionsService.createTransaction({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useConnectionsServiceCreateConnection = <TData = Common.ConnectionsServiceCreateConnectionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name?: string; appId: string; credentials?: unknown; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name?: string; appId: string; credentials?: unknown; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ConnectionsService.createConnection({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useConnectionsServiceConnectConnection = <TData = Common.ConnectionsServiceConnectConnectionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { appId: string; redirectTo?: string; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { appId: string; redirectTo?: string; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => ConnectionsService.connectConnection({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const usePaymentsServiceUpdatePayment = <TData = Common.PaymentsServiceUpdatePaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; paidAt?: string; connectionId?: string; currency?: string; description?: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; lines?: { type: string; description?: string; reservationId?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; paidAt?: string; connectionId?: string; currency?: string; description?: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; lines?: { type: string; description?: string; reservationId?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => PaymentsService.updatePayment({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceUpdateReservation = <TData = Common.ReservationsServiceUpdateReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { currency?: string; uniqueRef?: string; connectionId?: string; guestName?: string; bookerName?: string; checkIn?: string; checkOut?: string; listingId?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; bookingPlatform?: string; confirmationCode?: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials?: { type: string; description?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { currency?: string; uniqueRef?: string; connectionId?: string; guestName?: string; bookerName?: string; checkIn?: string; checkOut?: string; listingId?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; bookingPlatform?: string; confirmationCode?: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; financials?: { type: string; description?: string; amount: number; accountId?: string; accountRef?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => ReservationsService.updateReservation({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceUpdateListingOwnershipPeriod = <TData = Common.ListingsServiceUpdateListingOwnershipPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { listingId?: string; startAt?: string; endAt?: string; owners?: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { listingId?: string; startAt?: string; endAt?: string; owners?: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => ListingsService.updateListingOwnershipPeriod({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceUpdateListing = <TData = Common.ListingsServiceUpdateListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; connectionId?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnershipPeriod?: { startAt?: string; endAt?: string; owners: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; }; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; connectionId?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnershipPeriod?: { startAt?: string; endAt?: string; owners: { ownerId: string; split?: number; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem"; }; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => ListingsService.updateListing({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useOwnersServiceUpdateOwner = <TData = Common.OwnersServiceUpdateOwnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; email?: string; firstName?: string; name?: string; phone?: string; connectionId?: string; type?: "individual" | "company"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; email?: string; firstName?: string; name?: string; phone?: string; connectionId?: string; type?: "individual" | "company"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => OwnersService.updateOwner({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceUpdateTeam = <TData = Common.TeamsServiceUpdateTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { defaultCurrency?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { defaultCurrency?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => TeamsService.updateTeam({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceUpdateMultipleRecurringFeeListingSubscription = <TData = Common.RecurringFeesServiceUpdateMultipleRecurringFeeListingSubscriptionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { updates: { startAt?: string; endAt?: string; rate?: number; id: string; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { updates: { startAt?: string; endAt?: string; rate?: number; id: string; }[]; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTenantId }) => RecurringFeesService.updateMultipleRecurringFeeListingSubscription({ requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceUpdateRecurringFeeListingSubscription = <TData = Common.RecurringFeesServiceUpdateRecurringFeeListingSubscriptionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { startAt?: string; endAt?: string; rate?: number; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { startAt?: string; endAt?: string; rate?: number; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => RecurringFeesService.updateRecurringFeeListingSubscription({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceUpdateRecurringFee = <TData = Common.RecurringFeesServiceUpdateRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; name?: string; formula?: string; icon?: string; type?: "managementFee" | "additionalFee"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; name?: string; formula?: string; icon?: string; type?: "managementFee" | "additionalFee"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExistingPeriods" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => RecurringFeesService.updateRecurringFee({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceUpdateStatementLayout = <TData = Common.StatementsServiceUpdateStatementLayoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { name?: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections?: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections?: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { name?: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections?: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections?: { title: string; columns: { name: string; type: "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => StatementsService.updateStatementLayout({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceUpdateOwnerStatement = <TData = Common.StatementsServiceUpdateOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { collectionMode?: "auto" | "all" | "currentMonthOnly"; status: "draft" | "inReview" | "void" | "published"; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { collectionMode?: "auto" | "all" | "currentMonthOnly"; status: "draft" | "inReview" | "void" | "published"; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => StatementsService.updateOwnerStatement({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceUpdateTransaction = <TData = Common.TransactionsServiceUpdateTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { description?: string; accountId?: string; accountRef?: string; type?: "ownerExpense" | "expense" | "deposit"; date?: string; currency?: string; lines?: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; accountAssignment?: "deposit" | "adjustment_complaint" | "deferredRevenue" | "deposit_merchantFee" | "expense_markup" | "expense_salesTax" | "ownerStatement_credit"; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; contactId?: string; markup?: { accountId?: string; accountRef?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { description?: string; accountId?: string; accountRef?: string; type?: "ownerExpense" | "expense" | "deposit"; date?: string; currency?: string; lines?: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; accountAssignment?: "deposit" | "adjustment_complaint" | "deferredRevenue" | "deposit_merchantFee" | "expense_markup" | "expense_salesTax" | "ownerStatement_credit"; amount: number; taxBehavior?: "excluded" | "included"; taxRateId?: string; contactId?: string; markup?: { accountId?: string; accountRef?: string; description?: string; taxBehavior?: "excluded" | "included"; taxRateId?: string; markupRate?: number; amount?: number; }; listingId: string; reservationId?: string; }[]; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => TransactionsService.updateTransaction({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceInitTeam = <TData = Common.TeamsServiceInitTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTenantId }) => TeamsService.initTeam({ id, requestBody, xTenantId }) as unknown as Promise<TData>, ...options });
export const usePaymentsServiceDeletePayment = <TData = Common.PaymentsServiceDeletePaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => PaymentsService.deletePayment({ id, xTenantId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceDeleteReservation = <TData = Common.ReservationsServiceDeleteReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTenantId }) => ReservationsService.deleteReservation({ id, onLocked, xTenantId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceDeleteListingOwnershipPeriod = <TData = Common.ListingsServiceDeleteListingOwnershipPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTenantId }) => ListingsService.deleteListingOwnershipPeriod({ id, onLocked, xTenantId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceDeleteListing = <TData = Common.ListingsServiceDeleteListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTenantId }) => ListingsService.deleteListing({ id, onLocked, xTenantId }) as unknown as Promise<TData>, ...options });
export const useOwnersServiceDeleteOwner = <TData = Common.OwnersServiceDeleteOwnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTenantId }) => OwnersService.deleteOwner({ id, onLocked, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceDeleteTeam = <TData = Common.TeamsServiceDeleteTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  force?: boolean;
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  force?: boolean;
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ force, id, xTenantId }) => TeamsService.deleteTeam({ force, id, xTenantId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceDeleteRecurringFeeListingSubscription = <TData = Common.RecurringFeesServiceDeleteRecurringFeeListingSubscriptionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTenantId }) => RecurringFeesService.deleteRecurringFeeListingSubscription({ id, onLocked, xTenantId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceDeleteRecurringFee = <TData = Common.RecurringFeesServiceDeleteRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => RecurringFeesService.deleteRecurringFee({ id, xTenantId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceDeleteStatementLayout = <TData = Common.StatementsServiceDeleteStatementLayoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => StatementsService.deleteStatementLayout({ id, xTenantId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceDeleteOwnerStatement = <TData = Common.StatementsServiceDeleteOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, xTenantId }) => StatementsService.deleteOwnerStatement({ id, xTenantId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceDeleteTransaction = <TData = Common.TransactionsServiceDeleteTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTenantId }) => TransactionsService.deleteTransaction({ id, onLocked, xTenantId }) as unknown as Promise<TData>, ...options });
export const useAccountsServiceDeleteAccount = <TData = Common.AccountsServiceDeleteAccountMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTenantId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTenantId }) => AccountsService.deleteAccount({ id, onLocked, xTenantId }) as unknown as Promise<TData>, ...options });

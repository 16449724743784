import { Filter } from '@finalytic/components';
import type { journalEntryEntityType_enum } from '@finalytic/graphql';
import { type SelectItem } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useMemo } from 'react';

type Props = {
  value: Maybe<string>;
  setValue: (value: journalEntryEntityType_enum | null | undefined) => void;
};

export const JournalEntryTypeFilter = ({ setValue, value: initial }: Props) => {
  const options = useMemo<SelectItem<journalEntryEntityType_enum>[]>(
    () => [
      {
        label: 'Transaction',
        value: 'transaction',
      },
      {
        label: 'Reservation',
        value: 'reservation',
      },
      {
        label: 'Fee & Commission',
        value: 'recurringFee',
      },
      {
        label: 'Owner Statement',
        value: 'statement',
      },
    ],
    []
  );

  const value = options.find((i) => i.value === initial) || null;

  return (
    <Filter.Select
      value={value}
      label="Type"
      setValue={(v) => setValue(v?.value)}
      type="single"
      data={{
        options,
      }}
    />
  );
};

import { Filter } from '@finalytic/components';
import {
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import type { account_bool_exp, listing_bool_exp } from '@finalytic/graphql';
import { type SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { day, hasValue, toTitleCase } from '@finalytic/utils';
import { Group } from '@mantine/core';
import {
  getListingName,
  orderByAccount,
  whereAccounts,
  whereListings,
} from '@vrplatform/ui-common';
import { useState } from 'react';
import { JournalEntryPartyFilter } from './JournalEntryPartyFilter';
import { JournalEntryTypeFilter } from './JournalEntryTypeFilter';

export const useGeneralLedgerDetailFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    listingId: StringParam,
    party: StringParam,
    type: StringParam,
    date: StringParam,
    accountId: StringParam,
    accountClassification: StringParam,
  });

  const isFiltered = Object.values(filter).some(hasValue);

  return {
    filter,
    setFilter,
    isFiltered,
    reset: () =>
      setFilter({
        search: undefined,
        listingId: undefined,
        party: undefined,
        date: undefined,
        type: undefined,
      }),
  };
};

export const GeneralLedgerDetailFilter = () => {
  return (
    <Group>
      <SearchFilter />
      <DateFilter />
      <ListingFilter />
      <AccountFilter />
      <AccountClassificationFilter />
      <TypeFilter />
      <PartyFilter />
    </Group>
  );
};

const SearchFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const TypeFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <JournalEntryTypeFilter
      value={filter.type}
      setValue={(v) => setFilter({ type: v })}
    />
  );
};

const PartyFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <JournalEntryPartyFilter
      value={filter.party}
      setValue={(v) => setFilter({ party: v })}
    />
  );
};

const DateFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const ListingFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useGeneralLedgerDetailFilter();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, search, meId, GL }, { limit, offset }) => {
      const where: listing_bool_exp = whereListings({
        currentTeamId: teamId,
        dashboard: 'propertyManager',
        partnerTeamIds: [],
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingId }) => {
      if (!listingId) return null;

      const value =
        q
          .listings({ where: { id: { _eq: listingId } } })
          .map<SelectItem>((item) => ({
            label: getListingName(item),
            value: item.id,
          }))[0] || null;

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      keepPreviousData: true,
      variables: {
        listingId: filter.listingId,
      },
    }
  );

  const value = data?.value || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingId: v?.value })}
      type="single"
      label="Listing"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const AccountFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useGeneralLedgerDetailFilter();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: account_bool_exp = whereAccounts({
        tenantId: teamId,
        search,
      });

      const list = q
        .accounts({
          where,
          order_by: orderByAccount,
          limit,
          offset,
        })
        .map<SelectItem>((account) => ({
          label: account.title || 'No name',
          value: account.id,
          group: toTitleCase(account.classification || 'No classification'),
        }));

      const aggregate =
        q
          .accountAggregate({
            where,
          })
          ?.aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, args) => {
      if (!args.accountId) return null;

      return (
        q
          .accounts({
            where: {
              id: { _eq: args.accountId },
              tenantId: { _eq: args.teamId },
            },
            limit: 1,
          })
          .map<SelectItem>((x) => ({
            label: x.title!,
            value: x.id,
          }))[0] || null
      );
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      keepPreviousData: true,
      variables: {
        accountId: filter.accountId,
        teamId,
      },
    }
  );

  return (
    <Filter.Select
      value={data || null}
      setValue={(v) => setFilter({ accountId: v?.value })}
      type="single"
      label="Account"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const AccountClassificationFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();
  const [search, setSearch] = useState('');
  const [teamId] = useTeamId();

  const queryData = useInfiniteQuery(
    (q, args) => {
      const where = whereAccounts({
        tenantId: args.teamId,
        search: undefined,
        classification: undefined,
        type: undefined,
      });

      const aggregate =
        q
          .accountAggregate({
            where,
            distinct_on: ['classification'],
          })
          .aggregate?.count() || 0;

      const list = q
        .accounts({
          distinct_on: ['classification'],
          where,
          order_by: [{ classification: 'asc' }],
        })
        .map<SelectItem>((account) => ({
          label: toTitleCase(account.classification) || 'No classification',
          value: account.classification || 'no-classification',
        }));

      return {
        aggregate,
        list,
      };
    },
    {
      variables: {
        search: search?.trim(),
        teamId,
      },
    }
  );

  const value = filter.accountClassification
    ? {
        value: filter.accountClassification,
        label: toTitleCase(filter.accountClassification),
      }
    : null;

  return (
    <Filter.Select
      type="single"
      label={'Classification'}
      value={value}
      infiniteData={{ ...queryData, setSearch }}
      setValue={(v) => setFilter({ accountClassification: v?.value })}
      hideSearch
    />
  );
};

import {
  Button,
  Collapse,
  InputDay,
  InputPercentage,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  captureSentryError,
  gqlV2,
  useInfiniteQuery,
  useInvalidateQueries,
  useListingsServiceCreateListingOwnershipPeriod,
  useListingsServiceUpdateListingOwnershipPeriod,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { AlertTriangleIcon, Icon, PlusIcon, TrashIcon } from '@finalytic/icons';
import {
  IconButton,
  LoadingIndicator,
  SelectItem,
  showErrorNotification,
} from '@finalytic/ui';
import { Maybe, day, hasValue, sum, utc } from '@finalytic/utils';
import { Alert, Box, Group, Stack, Text } from '@mantine/core';
import { formatOwnerName, whereOwnersV2 } from '@vrplatform/ui-common';
import { useCallback, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

type FormInputs = {
  id: Maybe<string>;
  startDate: string;
  endDate: Maybe<string>;
  members: {
    ownerId: string | null;
    split: number;
  }[];
};

function useListingPeriodMutation(listingId: string) {
  const [teamId] = useTeamId();

  const invalidate = useInvalidateQueries(['listings', 'listingOwners']);

  const { mutateAsync: insert } =
    useListingsServiceCreateListingOwnershipPeriod({
      onSettled: invalidate,
    });

  const { mutateAsync: update } =
    useListingsServiceUpdateListingOwnershipPeriod({
      onSettled: invalidate,
    });

  const mutate = useCallback(
    async (values: FormInputs) => {
      const requestBody: Parameters<typeof insert>[0]['requestBody'] = {
        listingId,
        startAt: day(values.startDate).yyyymmdd(),
        endAt: values.endDate ? day(values.endDate).yyyymmdd() : undefined,
        owners: values.members
          .filter((x) => x.ownerId)
          .map((member) => ({
            ownerId: member.ownerId!,
            split: member.split,
          })),
      };

      if (values.id) {
        return update({
          id: values.id,
          requestBody: {
            ...requestBody,
            onConflict: 'updateExistingPeriods',
          },
        });
      } else {
        return insert({
          requestBody: {
            ...requestBody,
            onConflict: 'updateExistingPeriods',
          },
        });
      }
    },
    [teamId, listingId]
  );

  return {
    mutate,
  };
}

export const ListingPeriodForm = ({
  initialValues,
  onReset,
  loadingQuery,
  previousValues,
  listingId,
}: {
  onReset: () => void;
  initialValues: FormInputs | undefined;
  previousValues:
    | { id: string; startAt: string; endAt: Maybe<string> }
    | undefined;
  loadingQuery: boolean;
  listingId: string;
}) => {
  const methods = useForm<FormInputs>({
    values: initialValues
      ? {
          id: initialValues.id,
          endDate: initialValues.endDate
            ? utc(initialValues.endDate).yyyymmdd()
            : undefined,
          startDate: initialValues.startDate
            ? utc(initialValues.startDate).yyyymmdd()
            : day().yyyymmdd(),
          members: initialValues.members,
        }
      : {
          id: undefined,
          endDate: null,
          startDate: day().yyyymmdd(),
          members: [
            {
              ownerId: null,
              split: 100,
            },
          ],
        },
  });

  const { mutate } = useListingPeriodMutation(listingId);

  const handleSubmit = useCallback(
    async (input: FormInputs) => {
      try {
        await mutate(input).then(onReset);
      } catch (error: any) {
        const message = (error as any)?.body?.message;
        captureSentryError(error);
        showErrorNotification({
          title: 'Failed to save ownership',
          message:
            message ||
            error?.message ||
            'An error occurred while saving the ownership. Please try again later.',
        });
      }
    },
    [onReset]
  );

  const previousEndDate = useMemo(() => {
    if (previousValues) {
      if (previousValues.endAt) {
        const res = utc(previousValues.endAt).yyyymmdd();
        return day(res).toDate();
      }
      const res = utc(previousValues.startAt).yyyymmdd();
      return day(res).add(1, 'day').toDate();
    }

    return undefined;
  }, [previousValues?.startAt, previousValues?.endAt]);

  if (loadingQuery) return <LoadingIndicator isFullPageLoading size="sm" />;

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
        onSubmit={methods.handleSubmit(handleSubmit)}
        onReset={onReset}
      >
        <Stack>
          <Box>
            <Controller
              control={methods.control}
              defaultValue={day().yyyymmdd()}
              name="startDate"
              render={({ field, fieldState: { error } }) => (
                <InputWrapper
                  label="Ownership start"
                  error={error?.message}
                  inputWrapperOrder={['label', 'input', 'description', 'error']}
                >
                  <InputDay
                    value={field.value ? day(field.value).toDate() : null}
                    onChange={(value) => field.onChange(day(value).yyyymmdd())}
                    placeholder={day().yyyymmdd()}
                    error={!!error}
                    minDate={previousEndDate}
                  />
                </InputWrapper>
              )}
            />
          </Box>
          <Box mb="xl">
            <Controller
              control={methods.control}
              name="endDate"
              render={({ field, fieldState: { error } }) => (
                <InputWrapper
                  label={
                    <>
                      Ownership end{' '}
                      <Text component="span" sx={{ fontWeight: 400 }}>
                        (start of next ownership)
                      </Text>
                    </>
                  }
                  error={error?.message}
                  inputWrapperOrder={['label', 'input', 'description', 'error']}
                >
                  <InputDay
                    value={field.value ? day(field.value).toDate() : null}
                    onChange={(value) => field.onChange(day(value).yyyymmdd())}
                    minDate={
                      !initialValues
                        ? undefined
                        : day(initialValues.startDate).add(1, 'day').toDate()
                    }
                    placeholder={
                      !initialValues
                        ? 'Forever'
                        : day(initialValues.startDate).add(1, 'day').yyyymmdd()
                    }
                    error={!!error}
                    disabled={!initialValues}
                  />
                </InputWrapper>
              )}
            />
          </Box>

          <Box>
            <Owners />
          </Box>
        </Stack>

        <Group
          mt="md"
          sx={(theme) => ({
            position: 'sticky',
            bottom: 0,
            height: 60,
            left: 0,
            right: 0,
            paddingBottom: theme.spacing.md,
            backgroundColor: theme.white,
            borderTop: `1px solid ${theme.colors.gray[2]}`,
            paddingTop: theme.spacing.sm,
            paddingInline: theme.spacing.xs,
            marginRight: `-${theme.spacing.xs}`,
            marginLeft: `-${theme.spacing.xs}`,
          })}
        >
          <Button type="reset" disabled={methods.formState.isSubmitting}>
            Cancel
          </Button>
          <Button
            disabled={!methods.formState.isDirty && !!initialValues}
            loading={methods.formState.isSubmitting}
            sx={{ flexGrow: '1!important' as any }}
            type="submit"
            variant="primary"
          >
            Save changes
          </Button>
        </Group>
      </Box>
    </FormProvider>
  );
};

const Owners = () => {
  const { control, getValues, watch, formState } = useFormContext<FormInputs>();
  const [teamId] = useTeamId();

  const aggregate = useQuery(
    (q, { teamId }) => {
      const where = whereOwnersV2({
        teamId,
        search: undefined,
      });

      const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

      return {
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      keepPreviousData: true,
      variables: {
        teamId,
      },
    }
  );

  const { append, fields, remove } = useFieldArray({
    name: 'members',
    control,
    rules: {
      minLength: 1,
      required: true,
      validate: (value) => {
        const total = sum(value, 'split');
        if (total !== 100) {
          return 'All splits must sum to exactly 100%.';
        }
        return true;
      },
    },
  });

  const hasNoOwners = aggregate.data?.aggregate === 0;
  const rows = watch('members');

  return (
    <Collapse
      hideToggle
      title="Owners"
      rightSection={
        <IconButton
          disabled={hasNoOwners}
          onClick={() => {
            const currentSplitTotal = sum(getValues('members'), 'split');

            append({
              ownerId: null,
              split:
                currentSplitTotal < 100 && currentSplitTotal >= 0
                  ? 100 - currentSplitTotal
                  : 0,
            });
          }}
        >
          <PlusIcon />
        </IconButton>
      }
    >
      <Stack
        sx={(theme) => ({
          gap: theme.spacing.xs,
        })}
      >
        {fields.map((access, index) => {
          return (
            <Group key={access.id} wrap="nowrap" gap="xs" align="flex-start">
              <Controller
                control={control}
                name={`members.${index}.ownerId`}
                rules={{
                  required:
                    index === 0
                      ? 'Select at least one owner'
                      : 'Please select an owner',
                }}
                render={({ field, fieldState: { error } }) => {
                  const [search, setSearch] = useState('');

                  const alreadySelectedOwnerIds = rows
                    .filter((x) => x.ownerId !== field.value)
                    .map((x) => x.ownerId)
                    .filter(hasValue);

                  const queryData = useInfiniteQuery(
                    (
                      q,
                      { teamId, search, alreadySelectedOwnerIds },
                      { limit, offset }
                    ) => {
                      const where: gqlV2.owner_bool_exp = {
                        ...whereOwnersV2({
                          teamId,
                          search,
                        }),
                        id: { _nin: alreadySelectedOwnerIds },
                      };

                      const list = q
                        .owners({
                          where,
                          limit,
                          offset,
                          order_by: [
                            {
                              type: 'asc_nulls_first',
                            },
                            {
                              name: 'asc_nulls_last',
                              firstName: 'asc_nulls_last',
                            },
                          ],
                        })
                        .map<SelectItem>((owner) => ({
                          label: formatOwnerName(owner) || '',
                          value: owner.id,
                          icon: (
                            <Icon
                              icon={
                                owner.type === 'company'
                                  ? 'OfficeIcon'
                                  : 'UserIcon'
                              }
                              size={owner.type === 'company' ? 16 : 14}
                            />
                          ),
                        }));

                      const aggregate =
                        q.ownerAggregate({ where }).aggregate?.count() || 0;

                      return {
                        list,
                        aggregate,
                      };
                    },
                    {
                      skip: !teamId,
                      queryKey: 'owners',
                      variables: {
                        teamId,
                        alreadySelectedOwnerIds,
                        search: search?.trim(),
                      },
                    }
                  );

                  const options =
                    queryData.data?.pages.flatMap((x) => x.list) || [];
                  const value =
                    options.find((x) => x.value === field.value) || null;

                  return (
                    <InputWrapper required error={error?.message}>
                      <InputSelect
                        infiniteData={{ ...queryData, setSearch }}
                        type="single"
                        value={value}
                        setValue={(newValue) => {
                          field.onChange(newValue?.value || null);
                        }}
                        dropdownProps={{
                          withinPortal: true,
                        }}
                        inputProps={{
                          placeholder: 'Owner',
                          width: 220,
                          error: !!error,
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
              <Controller
                control={control}
                name={`members.${index}.split`}
                rules={{
                  required: 'Split is required',
                  min: {
                    value: 0.01,
                    message: 'Split must be greater than 0%',
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <InputWrapper required error={error?.message}>
                      <InputPercentage {...field} error={!!error} />
                    </InputWrapper>
                  );
                }}
              />
              {!!index && (
                <IconButton onClick={() => remove(index)} pt={5}>
                  <TrashIcon color="gray" size={16} />
                </IconButton>
              )}
            </Group>
          );
        })}
        {formState.errors.members?.root?.message && (
          <Alert
            icon={<AlertTriangleIcon color="red" size={16} />}
            title="Failed to validate"
            color="red"
          >
            {formState.errors.members.root?.message}
          </Alert>
        )}
      </Stack>
    </Collapse>
  );
};

import { useQuery, useTeamId } from '@finalytic/data';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  FolderOpenIcon,
  LoaderIcon,
  NoteTextIcon,
} from '@finalytic/icons';
import { MRT_ColumnDef } from '@finalytic/table';
import { Drawer } from '@finalytic/ui';
import { Maybe, day, formatCurrency, sum } from '@finalytic/utils';
import { Box, Center, LoadingOverlay, Stack, Title } from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo } from 'react';
import { getExpense } from '../../views/expenses/list/useExpenseTableQuery';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useExpenseDetailDrawer } from './useExpenseDetailDrawer';

function useExpenseQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .transactions({
            where: {
              id: { _eq: args.id },
            },
            limit: 1,
          })
          .map(getExpense)[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'expenses',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Expense = ReturnType<typeof getExpense>;

export const ExpenseDetailDrawer = () => {
  const { opened, close, expenseId } = useExpenseDetailDrawer();
  const { isLoading, data: expense } = useExpenseQuery(expenseId);

  return (
    <>
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            <Title order={4} pt={5}>
              Expense detail
            </Title>
          }
          loading={isLoading}
        />
        {!expense && !isLoading ? (
          'No expense details found'
        ) : (
          <Content expense={expense} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  expense,
  isLoading,
}: { expense: Maybe<Expense>; isLoading: boolean }) => {
  if (!expense) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: CircleDollarIcon,
            title: 'Amount',
            text: formatCurrency(expense.centTotal / 100, expense.currency),
          },
          {
            icon: LoaderIcon,
            title: 'Status',
            text: expense.status,
          },
          {
            icon: CalendarEventIcon,
            title: 'Date',
            text: day(expense.date).format('MMM D, YYYY'),
          },
          {
            icon: FolderOpenIcon,
            title: 'Bank/CC account',
            text: expense.bankAccount.title,
          },
          {
            icon: NoteTextIcon,
            title: 'Description',
            text: expense.description,
          },
        ]}
      />

      <Lines lines={expense.lines} currency={expense.currency} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type ExpenseLine = Expense['lines'][number];

const Lines = ({
  lines,
  currency,
}: { lines: ExpenseLine[]; currency: string }) => {
  const total = useMemo(
    () => formatCurrency(sum(lines, 'centTotal') / 100, currency),
    [currency, ...lines.map((line) => line.centTotal)]
  );

  const columns = useMemo<MRT_ColumnDef<ExpenseLine>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => (
          <Box>
            <Text component="span" display="block" size="sm">
              {row.original.listing.name}
            </Text>
            <Text component="span" display="block" size="xs" c="gray">
              {row.original.description}
            </Text>
          </Box>
        ),
      },
      {
        header: 'Split',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => formatCurrency(row.original.centTotal / 100),
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="Expense lines"
      rightSection={
        <Text fw={500} size="sm">
          {total}
        </Text>
      }
      rowData={lines}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No lines available
          </Text>
        </Center>
      )}
    />
  );
};

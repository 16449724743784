import {
  Button,
  CalendarFilterDateType,
  CountBagde,
  Filter,
} from '@finalytic/components';
import {
  useEnabledFeatures,
  useInvalidateQueries,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { Legend, SelectItem } from '@finalytic/ui';
import { day } from '@finalytic/utils';
import { useUnreadInboxNotificationsCount } from '@liveblocks/react';
import { Box, Group, Stack, Tabs, Title, useMantineTheme } from '@mantine/core';
import { useLocalStorage, useMediaQuery } from '@mantine/hooks';
import { currencies } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PmErrorIssueNotification } from '../../components';
import { usePmIssuesAggregateQuery } from '../../queries';
import { PmInboxTable } from './PmInboxTable';
import { PmIssuesTable } from './PmIssuesTable';
import {
  ActiveListingsCard,
  AverageDailyRateCard,
  ListCard,
  PaymentVolumeCard,
  ReservationVolumeCard,
} from './_components';

export const PmDashboard = () => {
  const { firstName } = useMe();

  const title = firstName
    ? `Hey ${firstName}, welcome on board!`
    : 'Hi there, welcome on board!';

  const invalidate = useInvalidateQueries(['issues']);

  return (
    <>
      <Group mt="xs" mb="md" justify="space-between">
        <Title id="welcome-message" order={1}>
          {title}
        </Title>
        <Button
          variant="light"
          onClick={() => invalidate()}
          leftIcon={'RefreshCwIcon'}
        >
          Refresh data
        </Button>
      </Group>
      <DashboardTabs />
    </>
  );
};

const DashboardTabs = () => {
  const { tabValue } = useParams<{ tabValue: string | undefined }>();
  const goto = useNavigate();
  const { LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();

  return (
    <>
      <PmErrorIssueNotification type="card" />
      <Tabs
        mt="sm"
        value={tabValue || 'overview'}
        keepMounted={false}
        onChange={(tab) => {
          goto(`/dashboard/${tab}`);
        }}
        styles={(theme) => ({
          root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            flex: 1,
          },
          panel: {
            height: '100%',
            flex: 1,
            marginTop: theme.spacing.md,
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab value="overview">Overview</Tabs.Tab>
          <Tabs.Tab value="issues">
            Issues <IssueCountBadge />
          </Tabs.Tab>
          {LIVEBLOCKS_NOTIFICATIONS && (
            <Tabs.Tab value="notifications">
              Notifications <NotificationCountBadge />
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="overview">
          <OverviewTab />
        </Tabs.Panel>

        {LIVEBLOCKS_NOTIFICATIONS && (
          <Tabs.Panel value="notifications">
            <PmInboxTable />
          </Tabs.Panel>
        )}

        <Tabs.Panel value="issues">
          <PmIssuesTable />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const NotificationCountBadge = () => {
  const { count } = useUnreadInboxNotificationsCount();

  if (!count) return null;

  return <CountBagde count={count} />;
};

const IssueCountBadge = () => {
  const { data, isInitialLoading: loading } = usePmIssuesAggregateQuery();

  const count = data?.aggregate || 0;

  if (loading) return <CountBagde count={count} loading={loading} />;

  if (!count) return null;

  return <CountBagde count={count} />;
};

const OverviewTab = () => {
  const now = day();
  const theme = useMantineTheme();
  const legendColor = theme.colors[theme.primaryColor][6];
  const [teamId] = useTeamId();

  const isMobileLayout = useMediaQuery('(max-width: 1078px)');

  const [filter, setFilter] = useLocalStorage<{
    date: CalendarFilterDateType;
    currency: string;
  }>({
    key: `${teamId}-pmd`, // pmd = pm dashboard
    defaultValue: {
      date: [null, null],
      currency: 'usd',
    },
  });

  const date = filter.date;

  const setDate = (date: CalendarFilterDateType) =>
    setFilter((prev) => ({ date, currency: prev.currency }));

  const start = date[0] ? day(date[0]) : now.subtract(30, 'day');
  const end = date[1] ? day(date[1]) : now;
  const diff = end.diff(start, 'days');
  const startDate = start.subtract(diff + 1, 'days').yyyymmdd();
  const endDate = end.yyyymmdd();

  const hasDate = date[0] && date[1];

  const {
    data,
    isLoading: loading,
    error,
  } = useQuery(
    (q, { currencies, ...args }) => {
      const curr = q
        .metricsCurrencies({
          args,
          where: {
            currency: { _is_null: false },
          },
        })
        .map<SelectItem>((item) => {
          const key = item.currency?.toUpperCase() as keyof typeof currencies;

          const currency = currencies[key] || currencies.USD;

          return {
            label: currency.currencyName,
            value: item.currency || 'usd',
          };
        });

      if (!curr.length)
        return [
          {
            label: currencies.USD.currencyName,
            value: 'usd',
          },
        ];

      return curr;
    },
    {
      variables: {
        startDate,
        endDate,
        tenantId: teamId,
        currencies,
      },
    }
  );

  const currency = useMemo(() => {
    const defaultCurrency = {
      label: currencies.USD.currencyName,
      value: 'usd',
    };

    if (filter.currency) {
      return (
        data?.find((item) => item.value === filter.currency) ||
        data?.[0] ||
        defaultCurrency
      );
    }

    return defaultCurrency;
  }, [data, filter.currency]);

  return (
    <>
      <Group justify="flex-start" mb="xs" mx={'sm'}>
        <Filter.Date
          value={hasDate ? date : [start.toDate(), end.toDate()]}
          setValue={(value) => setDate(value || [null, null])}
          clearable={false}
        />

        {loading || (data?.length || 1) < 2 ? null : (
          <Filter.Select
            value={currency}
            type="single"
            setValue={(value) =>
              value?.value &&
              setFilter((prev) => ({
                currency: value.value,
                date: prev.date,
              }))
            }
            hideClearButton
            label="Currency"
            data={{
              options: data || [],
              loading,
              error,
            }}
          />
        )}

        {/* Legend */}
        <Group ml="auto">
          <Legend legendColor={legendColor} label="Selected period" />
          <Legend label="Previous period" />
        </Group>
      </Group>

      <Group
        w="100%"
        gap={'sm'}
        sx={{
          justifyContent: 'space-evenly',
          alignItems: isMobileLayout ? 'stretch' : 'flex-start',
          flexDirection: isMobileLayout ? 'column' : 'row',
        }}
      >
        <Group
          gap={'sm'}
          sx={{
            width: isMobileLayout ? '100%' : '65%',
            justifyContent: 'stretch',
            flexDirection: isMobileLayout ? 'column' : 'row',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <PaymentVolumeCard
              endDate={endDate}
              startDate={startDate}
              currency={currency.value}
            />
          </Box>
          <Box sx={{ width: isMobileLayout ? '100%' : '49%' }}>
            <ReservationVolumeCard
              endDate={endDate}
              startDate={startDate}
              currency={currency.value}
            />
          </Box>
          <Box sx={{ width: isMobileLayout ? '100%' : '49%' }}>
            <AverageDailyRateCard
              endDate={endDate}
              startDate={startDate}
              currency={currency.value}
            />
          </Box>

          <Box sx={{ width: isMobileLayout ? '100%' : '49%' }}>
            <ActiveListingsCard
              endDate={endDate}
              startDate={startDate}
              currency={currency.value}
            />
          </Box>
        </Group>
        <Stack sx={{ width: isMobileLayout ? '100%' : '33%' }}>
          <ListCard type="listings" />
          <ListCard type="owners" />
          <ListCard type="connections" />
        </Stack>
      </Group>
    </>
  );
};

// const SetupSection = () => {
//   const [{ id: teamId, ...team }] = useTeam();

//   const emblaScroll = useRef(WheelGesturesPlugin({ forceWheelAxis: 'x' }));

//   const { data, isLoading: loading } = useQuery(
//     (q, args) => {
//       const memberCount =
//         q
//           .tenantUserAggregate({
//             where: {
//               tenantId: { _eq: args.teamId },
//               role: { _nin: ['owner', 'company'] },
//             },
//           })
//           .aggregate?.count() || 0;

//       const owners = q
//         .tenantUser({
//           where: { role: { _eq: 'owner' }, tenantId: { _eq: args.teamId } },
//         })
//         .map((user) => ({
//           id: user.id,
//           isInvited: ['active', 'unconfirmed'].includes(user.status || ''),
//         }));

//       const teamCompanyName = q.tenantById({ id: args.teamId })?.companyName;

//       const otaConnections =
//         q
//           .connectionAggregate({
//             where: {
//               status: whereConnectionStatusDefault,
//               tenantId: { _eq: args.teamId },
//               app: {
//                 category: {
//                   _eq: 'bookingChannel',
//                 },
//               },
//             },
//           })
//           .aggregate?.count() || 0;

//       const merchantConnections =
//         q
//           .connectionAggregate({
//             where: {
//               status: whereConnectionStatusDefault,
//               tenantId: { _eq: args.teamId },
//               app: {
//                 category: { _eq: 'paymentGateway' },
//               },
//             },
//           })
//           .aggregate?.count() || 0;

//       return {
//         memberCount,
//         owners,
//         teamCompanyName,
//         otaConnections,
//         merchantConnections,
//       };
//     },
//     {
//       variables: {
//         teamId,
//       },
//     }
//   );

//   const { total, count, ...components } = useMemo(() => {
//     const {
//       accountingPlatforms,
//       automations,
//       propertyManagementSystems,
//       colorPrimary,
//       logo,
//     } = team;

//     const statementHidden = automations.some((a) =>
//       a.template?.uniqueRef?.toLowerCase().endsWith('_ownerstatements')
//     );

//     const memberCount = data?.memberCount || 0;
//     const teamCompanyName = data?.teamCompanyName;

//     const owners = data?.owners;
//     const invitedOwners = data?.owners.filter((owner) => owner.isInvited);

//     const otaCount = data?.otaConnections || 0;
//     const merchantCount = data?.merchantConnections || 0;

//     const components = {
//       // Connections
//       pms: {
//         component: ConnectPmsCard,
//         hide: propertyManagementSystems.length > 0,
//       },
//       accounting: {
//         component: ConnectAccountingCard,
//         hide: accountingPlatforms.length > 0,
//       },
//       ota: {
//         component: ConnectOtaCard,
//         hide: otaCount > 0,
//       },

//       merchant: {
//         component: ConnectMerchantProcessorCard,
//         hide: merchantCount > 0,
//       },

//       // Members
//       teamMembers: { component: InviteMembersCard, hide: memberCount > 1 },

//       // Statements
//       statement: {
//         component: SetupStatementCard,
//         hide: statementHidden,
//       },

//       updateTeamDetails: {
//         component: UpdateTeamDetails,
//         hide: !!teamCompanyName,
//       },

//       statementTemplate: {
//         component: UpdateStatementTemplateCard,
//         hide: !statementHidden || colorPrimary || logo,
//       },

//       // Owners
//       addOwners: {
//         component: AddOwnersCard,
//         hide: !!owners?.length,
//       },
//       inviteOwners: {
//         component: InviteOwnersCard,
//         hide: !owners?.length || !!invitedOwners?.length,
//       },
//     };

//     return {
//       total: Object.keys(components).length,
//       count: Object.keys(components).filter(
//         (key) => components[key as keyof typeof components].hide
//       ).length,
//       ...components,
//     };
//   }, [team, data]);

//   if (total === count || loading) return null;

//   return (
//     <>
//       <Text component="p">
//         Finish account setup{' '}
//         <Text component="span" color="gray">
//           ({total - count} remaining)
//         </Text>
//       </Text>
//       <Carousel
//         slideSize="30%"
//         slidesToScroll={2}
//         align="start"
//         slideGap="md"
//         mb="xl"
//         // loop
//         withControls={count > 3}
//         draggable
//         styles={{
//           control: {
//             "&[tabindex='-1']": {
//               visibility: 'hidden',
//             },
//           },
//         }}
//         plugins={[emblaScroll?.current as any]}
//       >
//         {Object.entries(components).map(([key, item]) => {
//           const Card = item.component;

//           if (item.hide) return null;

//           return (
//             <Carousel.Slide key={key}>
//               <Card />
//             </Carousel.Slide>
//           );
//         })}
//       </Carousel>
//     </>
//   );
// };

import { LoadingIndicator } from '@finalytic/ui';
import { Textarea, TextareaProps, rem } from '@mantine/core';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { ComponentPropsWithRef, useEffect, useState } from 'react';

type Props = {
  loadingQuery?: boolean;
  loadingMutation?: boolean;
  setValue?: (v: string) => void;
  debounce?: number;
  width?: number;
  height?: number;
} & Omit<TextareaProps, 'onChange'> &
  ComponentPropsWithRef<'textarea'>;

export const InputTextarea = ({
  setValue,
  placeholder,
  loadingMutation,
  loadingQuery,
  debounce = 500,
  leftSection,
  value,
  height,
  width,
  sx,
  ...props
}: Props) => {
  const hasOnchange = !!props.onChange;

  const [input, setInput] = useState(value);

  const [delayed] = useDebouncedValue(input, debounce);

  useDidUpdate(() => {
    if (typeof delayed === 'string' && setValue) {
      setValue(delayed);
    }
  }, [delayed]);

  useEffect(() => {
    if (!hasOnchange) setInput(value);
  }, [value, hasOnchange]);

  return (
    <Textarea
      value={hasOnchange ? value : input}
      // onChange={(event) => setInput(event.currentTarget.value)}
      sx={(theme, u) => ({
        textarea: {
          height: height ?? '100%',
          width,
          minHeight: height ?? rem(150),
          '&:focus': {
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[props.error ? 'red' : theme.primaryColor][4]
            }40`,
            borderColor: props.error
              ? theme.colors.red[6]
              : theme.colors[theme.primaryColor][6],
          },
        },
        ".mantine-Input-section[data-position='right']": {
          pointerEvents: 'initial',
        },
        ...((typeof sx === 'function' ? sx(theme, u) : sx || {}) as any),
      })}
      radius="sm"
      placeholder={placeholder}
      leftSection={loadingQuery ? <LoadingIndicator size="xs" /> : leftSection}
      rightSection={loadingMutation && <LoadingIndicator size="xs" />}
      {...props}
    />
  );
};

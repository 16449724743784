import {
  ChevronIcon,
  CopyIcon,
  MoreHorizontalIcon,
  TrashIcon,
} from '@finalytic/icons';
import { IconDefinition, faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Menu,
  MenuItemProps,
  MenuProps,
  useMantineTheme,
} from '@mantine/core';
import { ReactNode } from 'react';
import { showSuccessNotification, useLegacyColors } from '../../../hooks';
import { ExtendedCustomColors } from '../../../styles';
import { Button } from '../../buttons';
import { LoadingIndicator } from '../../loading-indicator';

type EllipsisMenuItemProps = Omit<MenuItemProps, 'icon'> & {
  icon?: IconDefinition;
  component?: any;
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
  iconColor?: string;
  customIcon?: ReactNode;
  loading?: boolean;
  loadingColor?: ExtendedCustomColors;
};

export const EllipsisMenuDivider = Menu.Divider;

export const EllipsisMenuItem = ({
  children,
  icon,
  iconColor,
  customIcon,
  loading,
  loadingColor,
  ...props
}: EllipsisMenuItemProps) => (
  <Menu.Item
    leftSection={
      loading ? (
        <LoadingIndicator color={loadingColor} size="xs" />
      ) : (
        customIcon || (icon && <Icon icon={icon} color={iconColor} />)
      )
    }
    {...props}
  >
    {children}
  </Menu.Item>
);

export const EllipsisMenuDangerItem = (props: EllipsisMenuItemProps) => {
  const { red } = useLegacyColors();

  return (
    <EllipsisMenuItem
      {...props}
      // icon={faTrashAlt}
      customIcon={
        <TrashIcon
          size={16}
          strokeWidth={1.5}
          color={props.iconColor || red.base}
        />
      }
      sx={{ color: red.base }}
      iconColor={props.iconColor || red.base}
      loadingColor="gray"
    />
  );
};

export const EllipsisMenuCopyItem = ({
  value,
  notification,
  ...props
}: EllipsisMenuItemProps & {
  value: string;
  notification?: string;
}) => {
  const theme = useMantineTheme();

  return (
    <EllipsisMenuItem
      customIcon={<CopyIcon size={16} color={theme.colors.neutral[5]} />}
      disabled={!value}
      onClick={() => {
        navigator.clipboard.writeText(value).then(() =>
          showSuccessNotification({
            message: notification || 'ID was added to your clipboard.',
          })
        );
      }}
      {...props}
    >
      {props.children || 'Copy ID'}
    </EllipsisMenuItem>
  );
};

export const EllipsisMenuLabel = Menu.Label;

type Props = {
  children: ReactNode;
  icon?: IconDefinition;
  target?: ReactNode;
  label?: string;
} & MenuProps;

export const EllipsisMenu = ({
  children,
  trigger = 'hover',
  icon,
  label,
  target,
  ...menuProps
}: Props) => {
  const { gray, themeColors: colors } = useLegacyColors();

  return (
    <Menu
      shadow="md"
      width={200}
      radius="md"
      position="bottom-end"
      withArrow
      withinPortal
      trigger={trigger}
      styles={() => ({
        item: {
          height: 35,
        },
        label: {
          color: gray.dark,
          textTransform: 'uppercase',
        },
        arrow: {
          right: '10px !important',
        },
      })}
      {...menuProps}
    >
      <Menu.Target>
        {label ? (
          <Button
            sx={{ fontWeight: 500 }}
            leftSection={
              <ChevronIcon size={16} strokeWidth={1.5} color={gray.dark} />
            }
          >
            {label}
          </Button>
        ) : (
          target || (
            <ActionIcon
              disabled={menuProps.disabled}
              variant="subtle"
              color="gray"
            >
              {!icon ? (
                <MoreHorizontalIcon size={18} color={colors.neutral[4]} />
              ) : (
                <Icon icon={icon || faEllipsis} />
              )}
            </ActionIcon>
          )
        )}
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
};

const Icon = ({ icon, color }: { icon: IconDefinition; color?: string }) => {
  const { gray } = useLegacyColors();

  return <FontAwesomeIcon width={15} color={color || gray.dark} icon={icon} />;
};

import { Button, InputSelect, InputWrapper } from '@finalytic/components';
import {
  TrpcApiError,
  gqlV2,
  useEnabledFeatures,
  useInvalidateQueries,
  useMutation,
  useOwnersServiceDeleteOwner,
  useTrpcMutation,
} from '@finalytic/data';
import { useInfiniteQuery, useQuery } from '@finalytic/data';
import { ConfirmModal, SubmitConfirmModalType } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  DeleteModal,
  StringParam,
  showLoadingNotification,
  updateErrorNotification,
  updateSuccessNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import { SelectItem } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Center, Text, Title } from '@mantine/core';
import { Box, Group, Modal, rem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { formatOwnerName, whereOwnersV2 } from '@vrplatform/ui-common';
import { useId, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  owner: Maybe<{
    id: string;
    name: Maybe<string>;
    isArchived: boolean;
    tenantId: string;
  }>;
}

export const OwnerEllipsisMenuModals = ({
  deleteModal,
  archiveModal,
  owner: initial,
}: {
  owner: ModalProps['owner'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  migrateModal: {
    opened: boolean;
    closeModal: () => void;
  };
  archiveModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const owner = initial || debounced;

  return (
    <>
      <Archive {...archiveModal} owner={owner} />
      <Delete {...deleteModal} owner={owner} />
    </>
  );
};

export const Archive = ({ closeModal, opened, owner }: ModalProps) => {
  const { mutate } = useMutation(
    (q, args: { ownerId: string; status: gqlV2.owner_status_enum }) => {
      return q.updateOwner({
        pk_columns: { id: args.ownerId },
        _set: { status: args.status },
      })?.status;
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  const ownerId = owner?.id;
  const ownerName = owner?.name;
  const isArchived = owner?.isArchived;

  const archiveOwner: SubmitConfirmModalType = async () => {
    if (!ownerId)
      return {
        isSuccess: false,
        message: 'Owner ID is missing',
      };

    try {
      const result = await mutate({
        args: {
          ownerId,
          status: !isArchived ? 'inactive' : 'active',
        },
      });

      return {
        isSuccess: !!result,
      };
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage:
          error?.message ||
          "We couldn't update this owner. Please try again later.",
      };
    }
  };

  return (
    <ConfirmModal
      onClose={closeModal}
      onSubmit={archiveOwner}
      opened={opened}
      subtitle={
        <>
          <Center mb="xl">
            <Icon
              icon="FolderOpenIcon"
              size={32}
              strokeWidth={1.2}
              color={({ primaryColor, colors }) => colors[primaryColor][6]}
            />
          </Center>
          <Title order={4} fw={500}>
            Are you sure you want to {!isArchived ? 'archive' : 'unarchive'}
            <br />
            {ownerName || 'this owner'}?
          </Title>
          <Text component="p" mb={0}>
            {!isArchived
              ? 'The owner will be archived and hidden behind a table filter. Owners users will retain access to the data.'
              : "The owner will be unarchived and visible in the owner's table."}
          </Text>
        </>
      }
      title={''}
      zIndex={300}
      autoCloseOnSuccess
      submitButtonTitle={!isArchived ? 'Archive' : 'Unarchive'}
    />
  );
};

export const Delete = ({ closeModal, opened, owner }: ModalProps) => {
  const setOwner = useQueryParamSet('owner', StringParam);
  const { GL } = useEnabledFeatures();
  const invalidate = useInvalidateQueries(['owners']);
  const notifyId = useId();
  const { mutate } = useTrpcMutation('deleteOwner', {
    invalidateQueryKeys: ['owners'],
    successMessage: {
      title: 'Success!',
      message: 'Owner was deleted successfully.',
    },
  });

  const { mutateAsync: mutateV2 } = useOwnersServiceDeleteOwner({
    onSettled: () => {
      invalidate();
    },
  });

  const ownerId = owner?.id;
  const ownerName = owner?.name;
  const tenantId = owner?.tenantId;

  const deleteOwner = async () => {
    try {
      if (!ownerId || !tenantId) return;

      if (GL) {
        showLoadingNotification({
          id: notifyId,
        });

        const res = await mutateV2({ id: ownerId });
        if (res.status === 'deleted')
          updateSuccessNotification({
            id: notifyId,
            title: 'Owner Deletion',
            message: 'Owner was deleted successfully.',
          });
        else
          updateSuccessNotification({
            id: notifyId,
            title: 'Owner Archival',
            message: 'Owner was archived successfully.',
          });
      } else {
        await mutate({ ownerId, tenantId });
      }
      setOwner(undefined);
    } catch (err: any) {
      if (GL) {
        const error = err as TrpcApiError;
        updateErrorNotification({
          id: notifyId,
          title: 'Failed to delete owner',
          message: error?.body?.message || 'Failed to delete owner.',
        });
      }
    }
  };

  return (
    <DeleteModal
      onClose={closeModal}
      onSubmit={deleteOwner}
      opened={opened}
      subtitle="The owner will be entirely removed from your team."
      title={`Are you sure you want to delete ${ownerName || 'this owner'}?`}
      zIndex={300}
    />
  );
};

type FormInputs = {
  newOwnerId: string;
};

export const MigrateNewOwnerModal = ({
  closeModal,
  opened,
  owner,
}: ModalProps) => {
  const setOwner = useQueryParamSet('owner', StringParam);

  const ownerId = owner?.id;
  const ownerName = owner?.name;
  const tenantId = owner?.tenantId;

  const methods = useForm<FormInputs>();

  const { mutate } = useTrpcMutation('migrateOwner', {
    invalidateQueryKeys: ['owners'],
    successMessage: {
      title: 'Success!',
      message: 'Owner data was successfully migrated.',
    },
  });

  const onClose = () => {
    methods.reset();
    closeModal();
  };

  const submit = (values: FormInputs) => {
    if (!ownerId || !tenantId) return;

    return mutate({
      newOwnerId: values.newOwnerId,
      oldOwnerId: ownerId,
      tenantId,
    }).then((res) => {
      onClose();
      if (res.ok) setOwner(values.newOwnerId);
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      zIndex={300}
      radius="md"
      centered
      styles={{
        title: {
          fontWeight: 500,
        },
        body: {
          padding: rem(32),
        },
      }}
    >
      <Center mb="lg">
        <Icon
          icon="ShuffleIcon"
          size={24}
          color={({ colors, primaryColor }) => colors[primaryColor][5]}
        />
      </Center>
      <Title ta="center" order={4}>
        Migrate data to different owner
      </Title>

      <FormProvider {...methods}>
        <Box
          mt="xs"
          component="form"
          onReset={onClose}
          onSubmit={methods.handleSubmit(submit)}
        >
          <Text component="p" ta="center" mb="xs">
            Migrating will transfer all statements, ownerships and user access
            from {ownerName ? <b>{ownerName}</b> : 'the old owner'} to the new
            owner.
          </Text>
          {ownerId && tenantId && (
            <OwnerSelect ownerId={ownerId} tenantId={tenantId} />
          )}
          <Group mt="xl">
            <Button
              type="reset"
              sx={{
                flex: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              sx={{
                flex: 3,
              }}
            >
              Migrate data
            </Button>
          </Group>
        </Box>
      </FormProvider>
    </Modal>
  );
};

const OwnerSelect = ({
  ownerId,
  tenantId,
}: { tenantId: string; ownerId: string }) => {
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where: gqlV2.owner_bool_exp = {
        ...whereOwnersV2({ teamId: args.tenantId, search: args.search }),
        id: { _neq: args.ownerId },
      };

      const list = q
        .owners({
          where,
          limit,
          offset,
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map<SelectItem>((owner) => ({
          label: formatOwnerName(owner),
          value: owner.id,
          icon: (
            <Icon
              icon={owner.type === 'company' ? 'OfficeIcon' : 'UserIcon'}
              size={16}
            />
          ),
        }));

      const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;
      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        ownerId,
        tenantId,
        search: search?.trim(),
      },
    }
  );

  const { control } = useFormContext<FormInputs>();

  return (
    <Controller
      control={control}
      name="newOwnerId"
      rules={{
        required: 'Please select an owner to migrate to',
      }}
      render={({ field, fieldState: { error } }) => {
        const { data, isLoading: loadingValue } = useQuery(
          (q, args) => {
            return (
              q
                .owners({
                  where: {
                    id: { _eq: args.ownerId },
                  },
                  limit: 1,
                })
                .map((owner) => ({
                  label: formatOwnerName(owner),
                  value: owner.id,
                  icon: (
                    <Icon
                      icon={
                        owner.type === 'company' ? 'OfficeIcon' : 'UserIcon'
                      }
                      size={16}
                    />
                  ),
                }))[0] || null
            );
          },
          {
            skip: !field.value,
            variables: {
              ownerId: field.value,
              tenantId,
              search: search?.trim(),
            },
          }
        );

        return (
          <InputWrapper
            label="Owner"
            maw={350}
            mx="auto"
            error={error?.message}
          >
            <InputSelect
              value={data || null}
              setValue={(value) => field.onChange(value?.value || null)}
              type="single"
              infiniteData={{ ...queryData, setSearch }}
              inputProps={{
                placeholder: 'Select owner',
                error: !!error,
                loadingQuery: loadingValue,
              }}
              dropdownProps={{
                withinPortal: true,
                width: 'target',
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};
